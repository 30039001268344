import React from 'react';
import styled from 'styled-components';
import {Menu, Button} from 'antd';
import {useOutlet} from 'reconnect.js';
import qs from 'query-string';
import {BookOpen} from '@styled-icons/boxicons-regular/BookOpen';
import {Plus} from '@styled-icons/boxicons-regular/Plus';
import {Minus} from '@styled-icons/boxicons-regular/Minus';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import RichTextPreview from 'rev.sdk.js/Components/RichTextPreview';
import withPageEntry from '../../withPageEntry';
import * as AppActions from '../../AppActions';
import MemberRoster from '../../Components/MemberRoster';
import MemberBrand from '../../Components/MemberBrand';

const TITLE = '公眾服務';
const ROUTES = [
  {
    key: '/public-service?group=roster',
    label: '我要找律師',
    title: '會員名簿',
    children: [],
  },
  {
    key: '/public-service?group=brand',
    label: '商標法事務專區',
    children: [],
  },
  {
    key: '/public-service?group=society',
    label: '社會參與',
    children: [
      {
        key: '/public-service?group=society&category=human-rights',
        label: '人權委員會',
        children: [],
      },
      {
        key: '/public-service?group=society&category=law-helper',
        label: '法律扶助委員會',
        children: [],
      },
      {
        key: '/public-service?group=society&category=judicial-reform',
        label: '司法改革委員會',
        children: [],
      },
      {
        key: '/public-service?group=society&category=law-education',
        label: '法治教育委員會',
        children: [],
      },
      {
        key: '/public-service?group=society&category=environmental-law',
        label: '環境法委員會',
        children: [],
      },
      {
        key: '/public-service?group=society&category=family-law',
        label: '家事法委員會',
        children: [],
      },
      {
        key: '/public-service?group=society&category=lawyer-charity',
        label: '律師公益事務委員會',
        children: [],
      },
      {
        key: '/public-service?group=society&category=labor-law',
        label: '勞動法委員會',
        children: [],
      },
    ],
  },
  {
    key: '/public-service?group=subsidy',
    label: '論文補助',
    children: [],
  },
  {
    key: '/public-service?group=domain',
    label: '網域爭議名稱處理',
    children: [
      {
        key: '/public-service?group=domain&category=manual',
        label: '宣導手冊',
        children: [
          {key: '', label: '爭議處理辦法所處理的範圍'},
          {key: '', label: '網域名稱爭議處理的當事人'},
          {key: '', label: '註冊人的告知義務'},
          {key: '', label: '網域名稱爭議申訴的要件'},
          {key: '', label: '申訴書的內容與補正'},
          {key: '', label: '申訴書寄送與其他寄送方法'},
          {key: '', label: '聯絡上必須注意的事項'},
          {key: '', label: '答辯書的內容'},
          {key: '', label: '專家小組的組成'},
          {key: '', label: '專家小組的權限'},
          {key: '', label: '專家小組的決定與通知'},
          {key: '', label: '爭議處理程序的終止'},
          {key: '', label: '網域名稱的取消或移轉'},
          {key: '', label: '網域名稱爭議處理機構簡介'},
        ],
      },
      {
        key: '/public-service?group=domain&category=rules',
        label: '相關法規',
        children: [
          {key: '', label: '實施要點'},
          {key: '', label: '處理辦法'},
          {key: '', label: '附則'},
          {key: '', label: '補充說明'},
        ],
      },
      {
        key: '/public-service?group=domain&category=flow',
        label: '流程說明',
        children: [],
      },
      {
        key: '/public-service?group=domain&category=expert',
        label: '專家名單',
        children: [
          {
            key: '',
            label: '方雍仁 律師',
          },
          {
            key: '',
            label: '江國棟 律師',
          },
          {
            key: '',
            label: '何愛文 律師',
          },
          {
            key: '',
            label: '吳中仁 律師',
          },
          {
            key: '',
            label: '吳梓生 律師',
          },
          {
            key: '',
            label: '李宏澤 律師',
          },
          {
            key: '',
            label: '李佩昌 律師',
          },
          {
            key: '',
            label: '姜志俊 律師',
          },
          {
            key: '',
            label: '胡峰賓 律師',
          },
          {
            key: '',
            label: '張宇樞 律師',
          },
          {
            key: '',
            label: '張有㨗 律師',
          },
          {
            key: '',
            label: '莫詒文 律師',
          },
          {
            key: '',
            label: '許峻鳴 律師',
          },
          {
            key: '',
            label: '連世昌 律師',
          },
          {
            key: '',
            label: '陳志隆 律師',
          },
          {
            key: '',
            label: '陳淑貞 律師',
          },
          {
            key: '',
            label: '陳雅惠 律師',
          },
          {
            key: '',
            label: '陳鎮宏 律師',
          },
          {
            key: '',
            label: '陳麗雯 律師',
          },
          {
            key: '',
            label: '楊明興 律師',
          },
          {
            key: '',
            label: '葉玟妤 律師',
          },
          {
            key: '',
            label: '鄒志鴻 律師',
          },
          {
            key: '',
            label: '劉懿嫻 律師',
          },
          {
            key: '',
            label: '蔡淑美 律師',
          },
          {
            key: '',
            label: '蔡瑞森 律師',
          },
          {
            key: '',
            label: '蕭翊亨 律師',
          },
          {
            key: '',
            label: '藍弘仁 律師',
          },
          {
            key: '',
            label: '鍾文岳 律師',
          },
        ],
      },
      {
        key: '/public-service?group=domain&category=standard',
        label: '費用標準',
        children: [],
      },
      {
        key: '/public-service?group=domain&category=form',
        label: '書狀表格',
        children: [],
      },
      {
        key: '/public-service?group=domain&category=qa',
        label: 'Q & A',
        children: [],
      },
      {
        key: '/public-service?group=domain&category=announcement',
        label: '爭議決定公告',
        children: [],
      },
    ],
  },
  {
    key: '/public-service?group=literature',
    label: '法律文學',
    children: [],
  },
  {
    key: '/public-service?group=lecturer',
    label: '上市櫃講師',
    children: [],
  },
  {
    key: '/public-service?group=fraud',
    label: '詐騙警報',
    children: [],
  },
  {
    key: '/public-service?group=report',
    label: '倫理風紀專區',
    children: [],
  },
];

function PublicService(props) {
  const params = qs.parse(props.location.search);
  const [dimension] = useOutlet('dimension');
  const mobile = dimension.rwd === 'mobile';
  const [mobileNavVisible, setMobileNavVisible] = React.useState(false);
  const [record, setRecord] = React.useState(null);
  const [records, setRecords] = React.useState(null);
  const [expands, setExpands] = React.useState(null);

  const [modalData, setModalData] = React.useState(null);

  const handleCustomComponent = (search) => {
    if (search.indexOf('?group=roster') > -1) {
      return [true, <MemberRoster />];
    } else if (search.indexOf('?group=brand') > -1) {
      return [true, <MemberBrand />];
    }
    return [false, null];
  };

  const fetchData = React.useCallback(async () => {
    let route = getRouteInstance(params);
    let isCustomComponent = handleCustomComponent(props.location.search)[0];

    if (!route || isCustomComponent) {
      setRecord(null);
      setRecords(null);
      setExpands(null);

      return;
    }

    try {
      AppActions.setLoading(true);

      if (route.children.length === 0) {
        setRecords(null);
        setExpands(null);

        if (!record || record.title !== route.label) {
          setRecord(
            await JStorage.fetchOneDocument('Article_Default', {
              title: route.label,
            }),
          );
        }
      }

      if (route.children.length > 0) {
        setRecord(null);
        let recordsMatchRouteChildren = records?.every((r) => {
          let findIndex = route.children.findIndex((c) => c.label === r.title);
          return findIndex > -1;
        });

        if (!records || !recordsMatchRouteChildren) {
          setRecords(
            await JStorage.fetchAllDocuments('Article_Default', {
              title: {$in: route.children.map((c) => c.label)},
            }),
          );
          setExpands(
            route.children.map((c) => ({label: c.label, expand: false})),
          );
        }
      }

      AppActions.setLoading(false);
    } catch (err) {
      console.warn(err);
      setRecord(null);
      setRecords(null);
      setExpands(null);
      AppActions.setLoading(false);
    }
  }, [params, records, record, props.location.search]);

  React.useEffect(() => {
    fetchData();
  }, [params, fetchData, record]);

  const getRouteInstance = ({group, category}) => {
    let element;

    if (group) {
      element = ROUTES.find((item) => item.key.indexOf(group) > -1);

      if (category) {
        element = element.children.find(
          (item) => item.key.indexOf(category) > -1,
        );
      }
    }

    return element;
  };

  return (
    <Wrapper mobile={mobile}>
      <div className="menu">
        <Menu mode="inline">
          <Menu.Item className="header">{TITLE}</Menu.Item>
          {ROUTES.map((item, index) => {
            if (item.children.length > 0) {
              return (
                <Menu.SubMenu
                  key={`root-${index}`}
                  className="route"
                  title={item.label}>
                  {item.children.map((subroute, index) => (
                    <Menu.Item
                      key={`subroute-${index}`}
                      onClick={() => AppActions.navigate(subroute.key)}>
                      {subroute.label}
                    </Menu.Item>
                  ))}
                </Menu.SubMenu>
              );
            }
            return (
              <Menu.Item
                key={`root-${index}`}
                className="route"
                onClick={() => AppActions.navigate(item.key)}>
                {item.label}
              </Menu.Item>
            );
          })}
        </Menu>
      </div>
      <div className="gutter" />
      <div className="main">
        <h1 className="title">
          {getRouteInstance(params)?.title ||
            getRouteInstance(params)?.label ||
            '-- --'}
        </h1>
        {handleCustomComponent(props.location.search)[0] && (
          <div className="content">
            {handleCustomComponent(props.location.search)[1]}
          </div>
        )}

        {record && (
          <div className="content">
            <RichTextPreview key={record.id} content={record.content} />
          </div>
        )}

        {records && (
          <div className="content">
            {records.map((record, index) => {
              let findIndex = expands?.findIndex(
                (expand) => expand.label === record.title,
              );
              let isExpand = expands ? expands[findIndex]?.expand : false;

              return (
                <div className="expand-container" key={`expand-${index}`}>
                  <div
                    className="expand-title"
                    onClick={() => {
                      let nextValues = [...expands];
                      nextValues[findIndex].expand = !isExpand;
                      setExpands(nextValues);
                    }}>
                    {record.title}
                    <Button
                      className="expand-button"
                      type="text"
                      icon={
                        isExpand ? (
                          <Minus size={20} color="var(--primaryColor)" />
                        ) : (
                          <Plus size={20} color="var(--primaryColor)" />
                        )
                      }
                    />
                  </div>
                  {isExpand && (
                    <div className="expand-content">
                      <RichTextPreview
                        key={record.id}
                        content={record.content}
                      />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>

      <Button
        shape="circle"
        size="large"
        className="rwd-fixed-button"
        icon={<BookOpen size={22} color="var(--primaryColor)" />}
        onClick={() => setMobileNavVisible(true)}
      />
      <MobileNavDrawer
        visible={mobile && mobileNavVisible}
        setVisible={setMobileNavVisible}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin: var(--topNavBarHeight) auto 0px;
  max-width: var(--contentMaxWidth);
  width: 100%;
  min-height: 100vh;
  padding: 40px 20px;

  display: flex;
  justify-content: center;

  & > .menu {
    max-width: 210px;
    width: 100%;

    ${(props) => (props.mobile ? `display: none;` : `display: block;`)}

    & .header {
      border-bottom: 3px solid #c8b48c;
      text-align: center;
      font-weight: 500;
      font-size: 1rem;
      line-height: 2rem;
      color: var(--primaryColor);
    }

    & .ant-menu-root {
      border: 3px solid #c8b48c;
    }

    & .ant-menu-submenu-title {
      border-bottom: 3px solid #c8b48c;

      & > .ant-menu-title-content,
      .ant-menu-submenu-arrow {
        color: #ffffff;
      }
    }

    & .ant-menu-item,
    .ant-menu-item-only-child {
      border-bottom: 3px solid #c8b48c;
    }

    & .ant-menu-item:not(:last-child) {
      margin-top: 0px !important;
      margin-bottom: 0px !important;
      border-bottom: 3px solid #c8b48c;
    }

    & .ant-menu-inline .ant-menu-item:not(:last-child) {
      margin-bottom: 0px;
    }

    & .ant-menu-inline .ant-menu-submenu-title {
      margin-top: 0px;
      margin-bottom: 0px;
    }

    & .ant-menu-inline .ant-menu-item {
      margin-top: 0px;
      margin-bottom: 0px;
      border-bottom: 3px solid #c8b48c;
    }

    & .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: #ffffff;
      font-weight: 500;
      color: #000000;

      &::after {
        border-right: 1px solid #c8b48c;
      }
    }

    & .route {
      background-color: var(--thirdColor);
      color: #ffffff;
    }

    & .route:last-child {
      border-bottom: 0px;
    }
  }

  & > .gutter {
    width: 20px;
    ${(props) => (props.mobile ? `display: none;` : `display: block;`)}
  }

  & > .main {
    max-width: 910px;
    width: 100%;
    padding: 10px;

    & > .title {
      text-align: center;
      padding-bottom: 15px;
      border-bottom: 6px solid var(--primaryColor);
      margin: 0 auto;
      max-width: 850px;
    }

    & > .content {
      margin: 0 auto;
      max-width: 850px;
      padding-top: 15px;
      padding-bottom: 15px;
      white-space: pre-wrap;

      & > .expand-container {
        border-bottom: 3px solid #c8c8c8;
        padding-top: 10px;
        padding-bottom: 10px;

        & > .expand-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: var(--primaryColor);
          font-size: 0.95rem;
          cursor: pointer;
        }

        & > .expand-button {
        }

        & > .expand-content {
          padding-top: 15px;
          padding-bottom: 15px;
        }
      }
    }
  }

  & > .rwd-fixed-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    border: 1px solid var(--primaryColor);

    ${(props) => (props.mobile ? `display: block;` : `display: none;`)}
  }
`;

function MobileNavDrawer(props) {
  const {visible, setVisible} = props;
  const [dimension] = useOutlet('dimension');
  const mobile = dimension.rwd === 'mobile';

  return (
    <>
      <MobileNavDrawerBackdrop
        mobile={mobile}
        visible={visible}
        onClick={() => setVisible(false)}
      />
      <MobileNavDrawerWrapper mobile={mobile} visible={visible}>
        <div className="container">
          <Menu mode="inline">
            <Menu.Item className="header">{TITLE}</Menu.Item>
            {ROUTES.map((item, index) => {
              if (item.children.length > 0) {
                return (
                  <Menu.SubMenu
                    key={`root-${index}`}
                    className="route"
                    title={item.label}>
                    {item.children.map((subroute, index) => (
                      <Menu.Item
                        key={`subroute-${index}`}
                        onClick={() => {
                          AppActions.navigate(subroute.key);
                          setVisible(false);
                        }}>
                        {subroute.label}
                      </Menu.Item>
                    ))}
                  </Menu.SubMenu>
                );
              }
              return (
                <Menu.Item
                  key={`subroute-${index}`}
                  className="route"
                  onClick={() => {
                    AppActions.navigate(item.key);
                    setVisible(false);
                  }}>
                  {item.label}
                </Menu.Item>
              );
            })}
          </Menu>
        </div>
      </MobileNavDrawerWrapper>
    </>
  );
}

const MobileNavDrawerBackdrop = styled.div`
  width: 100vw;
  height: 100vh;

  position: absolute;
  top: 0px;
  left: 0px;
  background-color: rgba(255, 255, 255, 0.5);

  ${(props) => (props.visible ? `display: block;` : `display: none;`)};
`;

const MobileNavDrawerWrapper = styled.div`
  width: 256px;
  height: 100vh;

  position: absolute;
  top: 83px;
  left: -256px;
  transition: 300ms;

  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  padding: 30px 10px;

  ${(props) => (props.visible ? `left: 0px;` : ``)}

  & > .container {
    & .header {
      border-bottom: 3px solid #c8b48c;
      text-align: center;
      font-weight: 500;
      font-size: 1rem;
      line-height: 2rem;
      color: var(--primaryColor);
    }

    & .ant-menu-root {
      border: 3px solid #c8b48c;
    }

    & .ant-menu-submenu-title {
      border-bottom: 3px solid #c8b48c;

      & > .ant-menu-title-content,
      .ant-menu-submenu-arrow {
        color: #ffffff;
      }
    }

    & .ant-menu-item,
    .ant-menu-item-only-child {
      border-bottom: 3px solid #c8b48c;
    }

    & .ant-menu-item:not(:last-child) {
      margin-top: 0px !important;
      margin-bottom: 0px !important;
      border-bottom: 3px solid #c8b48c;
    }

    & .ant-menu-inline .ant-menu-item:not(:last-child) {
      margin-bottom: 0px;
    }

    & .ant-menu-inline .ant-menu-submenu-title {
      margin-top: 0px;
      margin-bottom: 0px;
    }

    & .ant-menu-inline .ant-menu-item {
      margin-top: 0px;
      margin-bottom: 0px;
      border-bottom: 3px solid #c8b48c;
    }

    & .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: #ffffff;
      font-weight: 500;
      color: #000000;

      &::after {
        border-right: 1px solid #c8b48c;
      }
    }

    & .route {
      background-color: var(--thirdColor);
      color: #ffffff;
    }

    & .route:last-child {
      border-bottom: 0px;
    }
  }

  max-width: 210px;
  width: 100%;
`;

export default withPageEntry(PublicService);
