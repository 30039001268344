import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import {Select, Input, Checkbox, Button, Table, Tag} from 'antd';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as AppActions from '../AppActions';
import {getUserSexLabel} from '../Utils/UserUtil';
import MemberRosterModal from './MemberRosterModal';

const INIT_DATA = {
  total: null,
  records: null,
  page: 1,
  pageSize: 10,
  expertises: [],
  keyword: '',
  areas: [],
  foreignLaw: false,
  efficient: false,
};

function MemberRoster(props) {
  const [expertiseCategoryDisplayMap] = useOutlet(
    'expertiseCategoryDisplayMap',
  );
  const [areaCategoryDisplayMap] = useOutlet('areaCategoryDisplayMap');

  const [total, setTotal] = React.useState(INIT_DATA.total);
  const [records, setRecords] = React.useState(INIT_DATA.records);
  const [modalData, setModalData] = React.useState(null);
  const [searchValues, setSearchValues] = React.useState({
    keyword: INIT_DATA.keyword,
    areas: INIT_DATA.areas,
    expertises: INIT_DATA.expertises,
  });

  const [page, setPage] = React.useState(INIT_DATA.page);
  const [pageSize, setPageSize] = React.useState(INIT_DATA.pageSize);
  const [expertises, setExpertises] = React.useState(INIT_DATA.expertises);
  const [keyword, setKeyword] = React.useState(INIT_DATA.keyword);
  const [areas, setAreas] = React.useState(INIT_DATA.areas);

  const [foreignLaw, setForeignLaw] = React.useState(INIT_DATA.foreignLaw);
  const [efficient, setEfficient] = React.useState(INIT_DATA.efficient);

  const fetchData = React.useCallback(
    async ({page, pageSize, searchValues}) => {
      try {
        AppActions.setLoading(true);

        const query = {
          state: 1,
          user_type: {$nin: ['extra_member', 'inter_member']},
          public: true,
        };

        if (!!searchValues.keyword) {
          query['$or'] = [
            {name: {$regex: searchValues.keyword}},
            {NAME_ENG: {$regex: searchValues.keyword}},
          ];
        }

        if (
          Array.isArray(searchValues.expertises) &&
          searchValues.expertises.length > 0
        ) {
          query['expertises'] = {
            $elemMatch: {
              name: {
                $in: searchValues.expertises,
              },
            },
          };
        }

        if (
          Array.isArray(searchValues.areas) &&
          searchValues.areas.length > 0
        ) {
          const sameCityCompanies = await JStorage.fetchAllDocuments(
            'Company',
            {
              address_city: {
                $in: searchValues.areas.map((area) => ({$regex: area})),
              },
            },
            ['-created'],
            {
              id: 1,
              CONAME: 1,
              address_city: 1,
            },
          );

          if (query.$or) {
            query.$and = [
              {$or: query.$or},
              {
                $or: [
                  {
                    company_city: {
                      $in: searchValues.areas.map((area) => ({$regex: area})),
                    },
                  },
                  {
                    company_current: {$in: sameCityCompanies.map((c) => c.id)},
                  },
                ],
              },
            ];

            delete query.$or;
          } else {
            query.$or = [
              {company_city: {$in: searchValues.areas}},
              {
                company_current: {$in: sameCityCompanies.map((c) => c.id)},
              },
            ];
          }
        }

        const upr = await JStorage.fetchDocuments(
          'user_profile',
          query,
          ['-created'],
          {
            offset: (page - 1) * pageSize,
            limit: pageSize,
          },
        );

        const companies = await JStorage.fetchAllDocuments(
          'Company',
          {
            _id: {
              $in: upr.results
                .filter((upr) => !!upr.company_current)
                .map((upr) => ({$oid: upr.company_current})),
            },
          },
          ['-created'],
          {
            id: 1,
            CONAME: 1,
            COPHONE1: 1,
            COFAX1: 1,
            CONAME_ENG: 1,
            address_zip_code: 1,
            address_city: 1,
            address_detail: 1,
          },
        );

        const nextUpr = {
          total: upr.total,
          results: upr.results.map((r) => {
            const cIdx = companies.findIndex((c) => c.id === r.company_current);
            return {
              ...r,
              cct_name: cIdx > -1 ? companies[cIdx].CONAME : '',
              cct_name_eng: cIdx > -1 ? companies[cIdx].CONAME_ENG : '',
              cct_phone: cIdx > -1 ? companies[cIdx].COPHONE1 : '',
              cct_fax: cIdx > -1 ? companies[cIdx].COFAX1 : '',
              cct_address_zip_code:
                cIdx > -1 ? companies[cIdx].address_zip_code : '',
              cct_address_city: cIdx > -1 ? companies[cIdx].address_city : '',
              cct_address_detail:
                cIdx > -1 ? companies[cIdx].address_detail : '',
            };
          }),
        };

        setRecords(nextUpr.results);
        setTotal(nextUpr.total);
      } catch (err) {
        console.warn(err);
      } finally {
        AppActions.setLoading(false);
      }
    },
    [],
  );

  React.useEffect(() => {
    fetchData({page, pageSize, searchValues});
  }, [fetchData, page, pageSize, searchValues]);

  return (
    <Wrapper>
      <div className="search">
        <div className="dashboard">
          <h2 className="title">搜尋律師</h2>
          <div className="field">
            <label className="name">律師專長領域</label>
            <Select
              style={{width: '100%'}}
              mode="multiple"
              allowClear
              placeholder="請選擇"
              defaultValue={expertises}
              options={Object.keys(expertiseCategoryDisplayMap).map((key) => ({
                label: expertiseCategoryDisplayMap[key],
                value: key,
              }))}
              value={expertises}
              onChange={(value) => setExpertises(value)}
            />
          </div>
          <div className="field">
            <label className="name">查詢關鍵字</label>
            <Input
              placeholder="律師姓名、英文姓名"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
            />
          </div>
          <div className="field">
            <label className="name">事務所地區</label>
            <Select
              style={{width: '100%'}}
              mode="multiple"
              allowClear
              placeholder="請選擇"
              options={Object.keys(areaCategoryDisplayMap).map((key) => ({
                label: key,
                value: key,
              }))}
              value={areas}
              onChange={(value) => setAreas(value)}
            />
          </div>
          {/* <div className="field">
            <Checkbox
              checked={foreignLaw}
              onChange={(e) => setForeignLaw(e.target.checked)}>
              外國法事務律師
            </Checkbox>
          </div>
          <div className="field">
            <Checkbox
              checked={efficient}
              onChange={(e) => setEfficient(e.target.checked)}>
              年限內是否曾辦理相關專長事務
            </Checkbox>
          </div> */}
          <div className="actions">
            <Button
              style={{marginRight: 10}}
              onClick={() => {
                setExpertises(INIT_DATA.expertises);
                setKeyword(INIT_DATA.keyword);
                setAreas(INIT_DATA.areas);

                setPage(INIT_DATA.page);
                setPageSize(INIT_DATA.pageSize);

                setSearchValues({
                  keyword: INIT_DATA.keyword,
                  areas: INIT_DATA.areas,
                  expertises: INIT_DATA.expertises,
                });
              }}>
              清除篩選條件
            </Button>
            <Button
              type="primary"
              onClick={() => {
                setPage(INIT_DATA.page);
                setPageSize(INIT_DATA.pageSize);

                setSearchValues({
                  keyword: keyword,
                  areas: areas,
                  expertises: expertises,
                });
              }}>
              搜尋
            </Button>
          </div>
          <p className="description">
            本欄位所收錄之律師名單，僅為本公會有意願公開個人資料於網站之會員，並無法查詢本公會所有會員之資料，如使用者欲查詢某位律師之資格，可至
            <a
              target="_blank"
              href="https://lawyerbc.moj.gov.tw/"
              rel="noreferrer"
              style={{marginRight: 5, marginLeft: 5}}>
              法務部律師查詢系統
            </a>
            查詢。
          </p>
          <p className="description">
            本欄位所刊載之會員資訊僅供律師業務聯繫之用，使用者不得轉用於招攬業務、行銷或其他用途，並應遵守個人資料保護法之相關規定。
          </p>
        </div>
      </div>

      {records && (
        <div className="results">
          <div className="display">
            <h2 className="title">搜尋結果</h2>
            <p className="total">共 {total} 筆</p>
          </div>

          <Table
            columns={[
              {
                title: '姓名',
                key: 'name',
                dataIndex: 'name',
                render: (_, r) => (
                  <div style={{maxWidth: 150, width: '100%'}}>
                    <div>
                      <Button
                        style={{paddingRight: 6, paddingLeft: 6}}
                        type="link"
                        onClick={() => setModalData(r)}>
                        <span
                          style={{
                            fontWight: 'bold',
                            fontSize: '1.2rem',
                          }}>
                          {r.name}
                        </span>
                      </Button>
                      ({getUserSexLabel(r.SEX)})
                    </div>
                    {r.NAME_ENG && (
                      <div style={{marginTop: 5, paddingLeft: 6}}>
                        {r.NAME_ENG}
                      </div>
                    )}
                  </div>
                ),
              },
              {
                title: '現在事務所',
                key: 'company',
                dataIndex: 'company',
                render: (_, r) => {
                  return (
                    <div style={{maxWidth: 200, width: '100%'}}>
                      <h3
                        style={{
                          marginBottom: 5,
                          fontWight: '500',
                          color: 'var(--secondColor)',
                        }}>
                        {r.cct_name}
                      </h3>
                      <div
                        style={{
                          marginBottom: 5,
                          fontSize: 14,
                          color: 'var(--secondColor)',
                        }}>
                        {r.cct_name_eng}
                      </div>
                      <div>
                        {r.company_zip_code || r.cct_address_zip_code || '無'}{' '}
                        {r.company_city || r.cct_address_city || '無'}
                        {r.company_detail || r.cct_address_detail || '無'}
                      </div>

                      <div>
                        電話：
                        {r.company_phone || r.cct_phone || '無'}
                      </div>

                      <div>
                        傳真：
                        {r.company_fax || r.cct_fax || '無'}
                      </div>
                    </div>
                  );
                },
              },
              {
                title: '專長領域',
                key: 'expertises',
                dataIndex: 'expertises',
                render: (_, record) => {
                  return (
                    <div style={{maxWidth: 220, width: '100%'}}>
                      {record.expertises?.map(
                        (e, i) =>
                          `${expertiseCategoryDisplayMap[e.name]}${
                            i + 1 === record.expertises.length ? '' : '、'
                          }`,
                      )}
                    </div>
                  );
                },
              },
            ]}
            dataSource={records}
            pagination={{
              current: page,
              pageSize: pageSize,
              total: total,
              onChange: (page, pageSize) => {
                setPage(page);
                setPageSize(pageSize);
              },
            }}
          />
        </div>
      )}

      <MemberRosterModal data={modalData} onClose={() => setModalData(null)} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > .search {
    margin-bottom: 25px;

    & > .dashboard {
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      margin: 0px auto 15px;
      padding: 25px;
      border-radius: 8px;
      max-width: 700px;
      width: 100%;

      & > .title {
        margin-bottom: 15px;
      }

      & > .field {
        margin-bottom: 15px;
        display: flex;
        align-items: center;

        & > label.name {
          max-width: 110px;
          width: 100%;
          font-size: 1rem;
        }
      }

      & > .actions {
        margin-bottom: 15px;
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  & > .results {
    width: 100%;

    & > .display {
      display: flex;
      align-items: end;
      margin: 20px 0px;

      & > .title {
        text-align: center;
        margin-right: 10px;
      }

      & > .total {
        font-weight: 500;
        text-align: center;
      }
    }
  }

  .ant-table-cell {
    padding: 16px 6px;
  }
`;

export default MemberRoster;
