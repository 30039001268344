import React from 'react';
import styled from 'styled-components';
import {Modal, Avatar} from 'antd';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import {UserOutlined} from '@ant-design/icons';
import {BookmarkStar} from '@styled-icons/boxicons-solid/BookmarkStar';
import {getUserSexLabel, getUserDegree} from '../Utils/UserUtil';

function MemberRosterModal(props) {
  const {data, onClose} = props;

  return (
    <Modal
      visible={!!data}
      width={750}
      onCancel={onClose}
      footer={null}
      maskClosable={true}
      destroyOnClose={true}>
      <ModalContent {...props} />
    </Modal>
  );
}

function ModalContent(props) {
  const {data: d, onClose} = props;

  return (
    <Wrapper>
      <div className="container">
        <div className="center-content">
          {d.avatar && <img alt="avatar" className="avatar" src={d.avatar} />}
          {!d.avatar && <Avatar size={80} icon={<UserOutlined />} />}
          <div className="certificate">
            {d.A_QUALIFY_WORD}字 第 {d.A_QUALIFY_NO} 號
          </div>
          <div className="name">
            {d.name} 律師
            <span className="sex">({getUserSexLabel(d.SEX)}) </span>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="left-content">
          <div className="part">
            <h3 className="title">現在事務所</h3>
            <div className="item">{d.cct_name}</div>
            <div className="item">{d.cct_name_eng}</div>
            <div className="item">
              {d.company_zip_code || d.cct_address_zip_code || '無'}{' '}
              {d.company_city || d.cct_address_city || '無'}
              {d.company_detail || d.cct_address_detail || '無'}
            </div>
            <div className="item">
              電話：
              {d.company_phone || d.cct_phone || '無'}
            </div>
            <div className="item">
              傳真：
              {d.company_fax || d.cct_fax || '無'}
            </div>
          </div>
          <div className="part">
            <h3 className="title">基本資料</h3>
            <div className="item">email：{d.email}</div>
            <div className="item">
              學歷：
              {d.educations?.map((edu, i) => {
                return `${edu.name || ''} ${getUserDegree(edu.DEGREE) || ''}${
                  i + 1 === d.educations.length ? '' : '、'
                }`;
              })}
            </div>
            <div className="item">
              經歷：
              {d.experiences?.map((exp, i) => {
                return `${exp.EVENT || ''} ${exp.POSITION || ''}${
                  i + 1 === d.experiences.length ? '' : '、'
                }`;
              })}
            </div>
            <div className="item">
              加入律師公會年月日：
              {d.inouts && Array.isArray(d.inouts) && d.inouts.length > 0
                ? `${d.inouts[d.inouts.length - 1].EFF_DATE?.split('T')[0]}`
                : ''}
            </div>
            <div className="item">
              曾否受過懲戒：請至
              <a
                className="link"
                target="_blank"
                href="https://lawyerbc.moj.gov.tw/"
                rel="noreferrer"
                style={{marginLeft: 3, marginRight: 3}}>
                法務部律師懲戒決議書
              </a>
              查詢
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  & > .container {
    flex: 1;
    padding: 10px;

    & > .center-content {
      display: flex;
      flex-direction: column;
      align-items: center;

      & > .avatar {
        margin-bottom: 10px;
        width: 150px;
        height: 190px;
        object-fit: contain;
      }

      & > .certificate {
        margin-bottom: 5px;
        font-size: 0.95rem;
      }

      & > .name {
        font-weight: 500;
        font-size: 1.25rem;
        color: #b49664;

        & > .sex {
          padding-left: 10px;
          font-size: 0.95rem;
          color: #000000;
        }
      }
    }

    & > .left-content {
      & > .part {
        margin-bottom: 10px;

        & > .title {
          font-size: 0.95rem;
        }

        & > .item {
          line-height: 1.7rem;
          font-size: 0.95rem;

          & > .link {
            border-bottom: 1px solid #b49664;
            padding-bottom: 2px;
          }
        }
      }
    }
  }
`;

export default MemberRosterModal;
